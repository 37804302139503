if($('.header--fixed').length){
    window.onscroll = function() {stickyHeader()};

    const navbar = document.getElementsByClassName("header--fixed");
    const sticky = navbar[0].offsetTop;
    stickyHeader();
}
function stickyHeader() {
    if (window.pageYOffset > sticky) {
        navbar[0].classList.add("sticky")
    } else {
        navbar[0].classList.remove("sticky");
    }
}