$('.apartment__slider').slick({
    centerMode: true,
    centerPadding: '14%',
    slidesToShow: 1,
    variableWidth: true,
    adaptiveHeight: true,
    nextArrow: '<div class="arrow-slide arrow-slide--next"></div>',
    prevArrow: '<div class="arrow-slide arrow-slide--prev"></div>',
    appendArrows: $('.apartment__arrows')
    // responsive: [
    //     {
    //         breakpoint: 768,
    //         settings: {
    //             arrows: false,
    //             centerMode: true,
    //             centerPadding: '40px',
    //             slidesToShow: 3
    //         }
    //     },
    //     {
    //         breakpoint: 480,
    //         settings: {
    //             arrows: false,
    //             centerMode: true,
    //             centerPadding: '40px',
    //             slidesToShow: 1
    //         }
    //     }
    // ]
});