if($('#apartmentMap').length){
    const street = $('#apartmentAddress').attr('data-address');
    // const zip_code = $('.localization__zip-code').text();
    const city = $('#apartmentCity').attr('data-city');

    fetch(`https://nominatim.openstreetmap.org/search/${street} ${city}?format=json&addressdetails=1&limit=1`)
    .then((response) => {
        return response.json();
    })
    .then((data) => {
        if(data.length){
            const localization = data[0];
            var map = L.map('apartmentMap').setView([localization.lat, localization.lon], '15');
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            }).addTo(map);
            map.scrollWheelZoom.disable();

            var customIcon = L.icon({
                //Ścieżka do ikony
                iconUrl: '/themes/default/assets/images/marker-map.png',
                //Odpowiada za wielkość ikony
                iconSize: [50, 50],
                //Odpowiada za część wskazującą na obiekt
                iconAnchor:   [25, 50],
                //odpowiada w jakim pkt ma wyskoczyc popup?
                popupAnchor:  [-3, -76]
            });

            var points = [
                ["P1", localization.lat, localization.lon]
            ];

            var marker = [];
            var i;
            for (i = 0; i < points.length; i++) {
                marker[i] = new L.Marker([points[i][1], points[i][2]], {
                    icon: customIcon
                });
                marker[i].addTo(map);
            }
        }else{
            throw 'Nie znaleziono lokazizacji o podanym adresie';
        }
    })
    .catch((err) => {
            console.error(err);
        }
    );
}