
$(function() {
    let media991 = window.matchMedia("(max-width: 991px)");

    if (media991.matches) {
        on991();
    } else {
        off991();
    }

    media991.addEventListener("change", function(media991) {
        if (media991.matches) {
            on991();
        }else{
            off991();
        }
    });

    function on991(){
        menuMobile();
        apartmentPriceChangePosition();
    }
    function off991(){
        menuMobile(false);
        apartmentPriceChangePosition(false);
    }

    function menuMobile(active=true) {
        if (active) {
            $(".header__nav").addClass('padding-container');
        }else{
            $(".header__nav").removeClass('padding-container');
        }
    }

    function apartmentPriceChangePosition(active=true){
        const priceWrapper = $('.apartment__price-wrapper');
        if(priceWrapper){
            if(active){
                priceWrapper.insertAfter('.apartment__slider-wrapper');
            }else{
                priceWrapper.appendTo('.apartment__box');
            }
        }
    }
});


